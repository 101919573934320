import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { stepsControllerAction } from '../../../../../store/reducers/stepsController';
import { stepsStoreDataAction } from '../../../../../store/reducers/stepsStoreData';
import SearchResulZero from './components/SearchResulZero';
import scotCodes from '../../../components/scottish-zip/scotZips.json';
import Loader from '../../../../Common/Loader'

const SearchList = ({ searchResult, loaderStatus }) => {
    const dispatch = useDispatch();
    const [selectedRadio, setSelectedRadio] = useState('');
    const [selectedItem, setSelectedItem] = useState();
    
    const handleSelectedStore = () => {                   
        const searchSubstring = selectedItem.store_postcode.substring(0, selectedItem.store_postcode.indexOf(' '));

        scotCodes.map(item => {
            if (searchSubstring.includes(item)) {
                dispatch(stepsStoreDataAction.detectScottish(true))                
            }
            return true;
        });

        dispatch(stepsStoreDataAction.saveClusterFromSearch(selectedItem));
        dispatch(stepsControllerAction.goToPlanogram());
    }    

    useEffect(() => {
        dispatch(stepsControllerAction.updateDataLayer({
            "event": "Enter Subsite",
            "dlv.page": '03 - Store Search Result',
            "dlv.url": '/search-result'
        }));
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    

    return(
        <Fragment>
                {loaderStatus ? <Loader /> : 
                <div>
                    <h2 className="component-title">Is this your store?</h2>

                    <div className="search-list-wrapper">
                        <div className="search-list">
                        {searchResult.length === 0 && <SearchResulZero />}
                            
                        {searchResult.map(item => {                            
                            return(
                                <label className="radio-btn" key={item.id}>
                                    <input 
                                        type="radio" 
                                        name={item.id} 
                                        checked={parseInt(selectedRadio) === item.id}
                                        value={item.id}
                                        onChange={e => {
                                            setSelectedItem(item)
                                            setSelectedRadio(e.target.value)
                                        }}
                                    />
                                    <div className="city">{item.store_name}</div>
                                    <div className="address">{item.store_address}</div>
                                    <span className="checkmark"></span>
                                </label>
                                );
                            }
                        )}
                        </div>
        
                        <div className="search-buttons">
                            {searchResult.length > 0 &&
                                <button 
                                    onClick={handleSelectedStore}
                                    className={`button ${selectedRadio.length > 0 ? '' : 'disable'}`}
                                    disabled={selectedRadio.length > 0 ? false : true}
                                >
                                    Yes, that's my store
                                </button>
                            }
                            <button 
                                onClick={() => dispatch(stepsControllerAction.previousNextStep("next"))}
                                className="button"
                            >
                                {searchResult.length === 0 ? "Next" : "My store isn't on that list"}
                            </button>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default SearchList;