import React, { useState, Fragment } from 'react';
import axios from 'axios';

import SearchList from './components/SearchList';
import Search from './components/Search';
import apiPath from '../../api';

const SearchStore = () => {
    const [searchListStatus, setSearchListStatus] = useState(false);
    const [searchField, setSearchField] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [searchError, setSearchError] = useState('');

    const searchApiCall = async () => {        
        if (searchField.trim().length > 2) {
            setLoaderStatus(true);
            setSearchListStatus(true);
            let stores = [];
            
            try {
                let res = await axios.get(`${apiPath}/_backend/api/stores/getStore?query_string=${searchField.trim()}`);
                
                if (res.data.stores === undefined) {
                    stores = [];
                } else {
                    setSearchError("");
                    stores = res.data.stores;
                }

                
            } catch(error) {
                setSearchError("Something went wrong");
                setLoaderStatus(false);
                setSearchListStatus(false);
            }
            
            setSearchResult(stores);
            setLoaderStatus(false);
        }
    }
    
    const handleNext = () => {
        searchApiCall();
    }

    const handleSearch = e => {        
        let cloneValue = e.target.value === " " && searchField.length === 0 ? e.target.value.replace(/\s/g, '') : e.target.value;        

        setSearchField(cloneValue);
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter'){
            searchApiCall();
        }
    }
    
    return(
        <Fragment>
            
            {searchListStatus ? 
                <SearchList 
                    searchResult={searchResult}
                    loaderStatus={loaderStatus}
                /> 
            : 
                <Search 
                    handleNext={handleNext} 
                    searchField={searchField}
                    handleKeyPress={handleKeyPress}
                    handleSearch={handleSearch}
                    searchError={searchError}
                />
            }
        </Fragment>
    ); 
}

export default SearchStore;