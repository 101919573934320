import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { stepsControllerAction } from '../../../store/reducers/stepsController';
import { stepsStoreDataAction } from '../../../store/reducers/stepsStoreData';
import apiPath from '../../api';

const Planograms = () => {
    const [activePlanogram, setActivePlanogram] = useState('')
    const [activePlanogramDownloadAll, setActivePlanogramDownloadAll] = useState('')
    const scottishDetected = useSelector(store => store.stepsData.scottishDetected);
    const cluster = useSelector(store => store.stepsData.collectedData.storeSelectedCluster.cluster);
    const collectedData = useSelector(store => store.stepsData.collectedData);
    const selectedItemsShoppersStore = useSelector(store => store.stepsData.selectedItemsShoppersStore);
    const dispatch = useDispatch();
    
    useEffect(() => {        
        if (scottishDetected && cluster === 3) {
            setActivePlanogram("Bestway-Scotland-High-St-G&G-1.5m-Planogram.pdf");            
            setActivePlanogramDownloadAll("Bestway-Scotland-High-St-G&G-1.5m-Planogram.zip");
            return false;

        } else if (scottishDetected && (cluster === 1 || cluster === 2 || cluster === 4)) {
            setActivePlanogram("Bestway-Scotland-All-Clusters-1m-2m-3m-Planograms.pdf");
            setActivePlanogramDownloadAll("Bestway-Scotland-All-Clusters-1m-2m-3m-Planograms.zip");
            return false;
        }
        
        if (!scottishDetected && cluster === 3) {
            setActivePlanogram("Bestway-High-St-G&G-1.5m-Planogram.pdf");
            setActivePlanogramDownloadAll("Bestway-High-St-G&G-1.5m-Planogram.zip");
            return false;

        } else if (!scottishDetected && (cluster === 1 || cluster === 2 || cluster === 4)) {
            setActivePlanogram("Bestway-All-Clusters-1m-2m-3m.pdf");
            setActivePlanogramDownloadAll("Bestway-All-Clusters-1m-2m-3m.zip");
            return false;
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (collectedData.hasOwnProperty("storeInfo") && collectedData.hasOwnProperty("storeCloseToStrings")) {
            const { 
                storeInfo, 
                storeLocatedSelectedItem,
                storeSizeSelectedItem,
                storeTurnover,
                storeSelectedCluster,
                storeCloseToStrings
            } = collectedData;
        
            const preparingCollectedData = {
                store_name: storeInfo.storeName,
                store_address: storeInfo.street,
                store_postcode: storeInfo.postal,
                store_cluster: storeSelectedCluster.cluster,
                store_location: storeLocatedSelectedItem.title,
                shopper_demographic_rank_1: selectedItemsShoppersStore.rankOne.title,
                shopper_demographic_rank_2: selectedItemsShoppersStore.rankTwo.title,                
                shopper_demographic_rank_3: selectedItemsShoppersStore.rankThree.title,
                store_size: storeSizeSelectedItem.title,
                store_amenities: storeCloseToStrings.join('|'),
                store_average_weekly_turnover: storeTurnover,
            }

            const postStore = () => {
                axios.post(`${apiPath}/_backend/api/stores/add`, JSON.stringify(preparingCollectedData), {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }

            postStore();
        }

        dispatch(stepsControllerAction.updateDataLayer({
            "event": "Enter Subsite",
            "dlv.page": '10 - Planograms',
            "dlv.url": '/planogram',
            "dlv.cluster": cluster
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const resetSteps = () => {
        dispatch(stepsControllerAction.resetStep())
        dispatch(stepsStoreDataAction.resetCollecetedData())
    }

    const recommendationProducts = () => {
        dispatch(stepsControllerAction.updateDataLayer({
            "event": 'product-recommendation',
            "dlv.url": `https://www.bestwaywholesale.co.uk/categoryadvice/crisps-snacks-category-tool/cluster${cluster}`
        }));

        window.parent.location.href = `https://www.bestwaywholesale.co.uk/categoryadvice/crisps-snacks-category-tool/cluster${cluster}`;
    }

    const handleDownload = e => {        
        let downloadPlanogramName = e.currentTarget.getAttribute("data-planogram");
        
        dispatch(stepsControllerAction.updateDataLayer({
            "event": 'download',
            "dlv.planogram": downloadPlanogramName
        }));
    }

    return(
        <div>
            <div className='container no-padding'>
                <h2 className="component-title">Click to download your personalised Crisps & Snacks planograms and NEW HFSS legislation guidance document</h2>

                <div className='planograms_container_wrapper'><div className={`planograms_container ${cluster === 2 && 'planograms_container--four-in-row'}`}>
                    <div className='planogram'>
                        <a 
                            href={`/assets/planograms/main-aisle-planograms/${activePlanogram}`} 
                            onClick={handleDownload} 
                            data-planogram="Main Aisle Planogram"
                            download
                        >
                            <div className='planogram__image--lg'>
                                <img alt="planogram" src='/assets/main_aisle.jpg' />
                                <div className='check-box'>
                                    <img src='/assets/icons/download.png' alt="download planogram" />
                                </div>
                            </div>
                        </a>
                        
                        <div className='planogram__text'>
                            <p>Main Aisle Planogram</p>
                        </div>
                    </div>
                    <div className='planogram'>
                        <a 
                            href={`/assets/hfss/Walkers_Guide_To_Getting_Ready_For_HFSS.pdf`} 
                            onClick={handleDownload} 
                            data-planogram="HFSS"
                            download
                        >
                            <div className='planogram__image--auto'>
                                <img alt="planogram" src='/assets/hfss-download.jpg' />
                                <div className='check-box'>
                                    <img src='/assets/icons/download.png' alt="download planogram" />
                                </div>
                                <button className='button transparent'>PDF Download</button>
                            </div>
                        </a>
                        
                        <div className='planogram__text'>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
                <div className={`planograms_container ${cluster === 2 && 'planograms_container--four-in-row'}`}>
                    <div className='planogram'>
                        <a 
                            href="/assets/planograms/front-of-store-planograms.zip" 
                            onClick={handleDownload} 
                            data-planogram="Front of Store Planogram"
                            download
                        >

                            <div className='planogram__image'>
                                <img alt="planogram" src='/assets/front_of_store.jpg'/>
                                <div className='check-box'>
                                    <img src='/assets/icons/download.png' alt="download planogram" />
                                </div>
                            </div>
                        </a>

                        <div className='planogram__text'>
                            <p>Front of Store Planogram</p>
                        </div>
                    </div>

                    <div className='planogram'>
                        <a 
                            href="/assets/planograms/feature-space-planograms.zip" 
                            onClick={handleDownload} 
                            data-planogram="Feature Space Planogram"
                            download
                        >

                            <div className='planogram__image'>
                                <img alt="planogram" src='/assets/feature_space.jpg'/>
                                <div className='check-box'>
                                    <img src='/assets/icons/download.png' alt="download planogram" />
                                </div>
                            </div>
                        </a>
                        <div className='planogram__text'>
                            <p>Feature Space Planogram</p>
                        </div>
                    </div>

                    {
                        cluster === 2 &&
                        <div className='planogram'>
                            <a 
                                href="/assets/planograms/soft-drinks-planograms.zip" 
                                onClick={handleDownload} 
                                data-planogram="Soft Drinks Space Planogram"
                                download
                            >

                                <div className='planogram__image--last'>
                                    <img alt="planogram" src='/assets/soft-drinks-space.jpg' />
                                    <div className='check-box'>
                                        <img src='/assets/icons/download.png' alt="download planogram" />
                                    </div>
                                </div>
                            </a>
                            <div className='planogram__text'>
                                <p>Soft Drinks Space Planogram</p>
                            </div>
                        </div>
                    }
                
                    {
                        cluster === 3 &&
                        <div className='planogram'>
                            <a 
                                href="/assets/planograms/food-to-go-planograms.zip" 
                                onClick={handleDownload} 
                                data-planogram="Food To Go Space Planogram"
                                download
                            >

                                <div className='planogram__image--last'>
                                    <img alt="planogram" src='/assets/food-to-go-space.jpg' />
                                    <div className='check-box'>
                                        <img src='/assets/icons/download.png' alt="download planogram" />
                                    </div>
                                </div>
                            </a>
                            <div className='planogram__text'>
                                <p>Food To Go Space Planogram</p>
                            </div>
                        </div>
                    }

                    {
                        cluster !== 3 &&
                        <div className='planogram'>
                            <a 
                                href="/assets/planograms/alcohol-aisle-planograms.zip" 
                                onClick={handleDownload} 
                                data-planogram="Alcohol Aisle Planogram"
                                download
                            >

                                <div className='planogram__image--last'>
                                    <img alt="planogram" src='/assets/alcohol_aisle.jpg' />
                                    <div className='check-box'>
                                        <img src='/assets/icons/download.png' alt="download planogram" />
                                    </div>
                                </div>
                            </a>
                            <div className='planogram__text'>
                                <p>Alcohol Aisle Planogram</p>
                            </div>
                        </div>
                    }
                </div></div>

                <div className='button-container--planograms'>
                    <button 
                        type="button" 
                        className='button grey'
                        onClick={resetSteps}
                    >

                        Back to start
                    </button> 
                    <a 
                        href={`/assets/planograms/download-all/${activePlanogramDownloadAll}`} 
                        className='button text-center'
                        onClick={handleDownload}
                        data-planogram="Download all planograms"
                    >
                        Download all planograms and HFSS legislation guidance
                    </a> 
                </div>

                <div className="text-center">
                    <button 
                        className='button wide'
                        onClick={recommendationProducts}
                    >
                        Click here to receive your personalised product recommendations
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Planograms;