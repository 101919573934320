import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Card from '../../Common/Card';
import { stepsControllerAction } from '../../../store/reducers/stepsController';
import { stepsStoreDataAction } from '../../../store/reducers/stepsStoreData';

const StoreShoppers = () => {
    const storeShoppers = useSelector(store => store.stepsData.stepsInitData.storeShoppers);    
    const selectedItemsShoppersStore = useSelector(store => store.stepsData.selectedItemsShoppersStore);    
    
    const dispatch = useDispatch();
    
    const handleNext = () => {
        // Go to next ste validation, if user select all of these three ranks
        if (selectedItemsShoppersStore.hasOwnProperty('rankOne') &&
            selectedItemsShoppersStore.hasOwnProperty('rankTwo') &&
            selectedItemsShoppersStore.hasOwnProperty('rankThree')) {

                dispatch(stepsControllerAction.previousNextStep("next"));
        }
        
        if (!selectedItemsShoppersStore.hasOwnProperty('rankOne') || 
            !selectedItemsShoppersStore.hasOwnProperty('rankTwo') ||
            !selectedItemsShoppersStore.hasOwnProperty('rankThree')) {                
                return false;
        }
        

        if (Object.is(selectedItemsShoppersStore.rankOne.cluster.type, selectedItemsShoppersStore.rankTwo.cluster.type)) {
            dispatch(stepsStoreDataAction.saveCluster({
                cluster: selectedItemsShoppersStore.rankOne.cluster.type
            }));

            return false;
        } else if ( Object.is(selectedItemsShoppersStore.rankOne.cluster.type, selectedItemsShoppersStore.rankThree.cluster.type)) {
            dispatch(stepsStoreDataAction.saveCluster({
                cluster: selectedItemsShoppersStore.rankOne.cluster.type
            }));

            return false;
        } else if (Object.is(selectedItemsShoppersStore.rankTwo.cluster.type, selectedItemsShoppersStore.rankThree.cluster.type)) {
            dispatch(stepsStoreDataAction.saveCluster({
                cluster: selectedItemsShoppersStore.rankTwo.cluster.type
            }));

            return false;
        } else {
            dispatch(stepsStoreDataAction.saveCluster({
                cluster: selectedItemsShoppersStore.rankOne.cluster.type
            }));
        } 
    }

    useEffect(() => {
        dispatch(stepsControllerAction.updateDataLayer({
            "event": "Enter Subsite",
            "dlv.page": '06 - Store Shoppers',
            "dlv.url": '/store-shoppers'
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    return(
        <div>
            <h2 className="component-title">Who shops in your store (Rank the top 3 in order of volume: <span className='highlight__red'>1 = Highest volume of shopper</span>)</h2>

            <div className='cards-container'>
                {storeShoppers?.map((shopper, i) =>
                    <Card 
                        key={i}
                        cardData={shopper}
                        onClick={() => dispatch(stepsStoreDataAction.handleStoreShoppers(shopper))}
                    />
                )}
            </div>
            
            <div className='button-container'>
                <button
                    className="button grey"    
                    onClick={() => dispatch(stepsControllerAction.previousNextStep("previous"))}
                >
                    Previous
                </button> 
                <button
                    className="button"    
                    onClick={handleNext}
                >
                    Next 
                </button> 
            </div>
        </div>
    );
}

export default StoreShoppers;