import React from 'react';
import { useSelector } from 'react-redux';

import IntroPage from './IntroPage';
import Layout from './Layout';
import StoreInfo from './Steps/StoreInfo';
import SearchStore from './Steps/SearchStore';
import StoreLocated from './Steps/StoreLocated';
import StoreShoppers from './Steps/StoreShoppers';
import StoreSize from './Steps/StoreSize';
import StoreCloseTo from './Steps/StoreCloseTo';
import StoreTurnover from './Steps/StoreTurnover';
import Planograms from './Steps/Planograms';
import ProgressBar from './Steps/components/ProgressBar';

const App = () => {
    const stepsController = useSelector(store => store.stepsController);
    const activeStep = useSelector(store => store.stepsController.activeStep);

    const renderSteps = () => {
        switch(activeStep) {
            case 0:  
                return <SearchStore />
            case 1:
                return <StoreInfo /> 
            case 2:
                return <StoreLocated />
            case 3:
                return <StoreShoppers />
            case 4:
                return <StoreSize />
            case 5:
                return <StoreCloseTo />
            case 6:
                return <StoreTurnover />
            case 7:
                return <Planograms />
            default:
                return <SearchStore />
        }
    }

    return(
        <div>
            {(!stepsController.goToStoreStatus && <IntroPage />) ||
                <Layout>
                    {activeStep > 1 && <ProgressBar />}
                    {renderSteps()}
                </Layout>
            }
        </div> 
    )
}

export default App;