import { createSlice, current } from '@reduxjs/toolkit';
import stepsInitData from '../stepsInitData/stepsInitData.json'

const stepsStoreData = createSlice({
    name: "stepsStoreData",
    initialState: {
        stepsInitData: stepsInitData,
        selectedItemsShoppersStore: {},
        scottishDetected: false,
        collectedData: {},
        shoppersCalculateRank: {},
    },
    reducers: {
        saveStoreInfo(state, action) {
            const cloneStoreInfo = {};
            
            for (const property in action.payload) {
                cloneStoreInfo[property] = action.payload[property].trim();
            }

            state.collectedData.storeInfo = cloneStoreInfo;
        },
        handleStoreLocated(state, action) {
            // save selected item from Store Location step
            state.collectedData.storeLocatedSelectedItem = action.payload;

            let cloneState = current(state.stepsInitData.storeLocated);
            const { id } = action.payload;
            const updatedData = [];
            

            cloneState.forEach(item => {
                let cloneItem = {...item};
                
                cloneItem.selected = false;
                
                if (cloneItem.id === id) {
                    cloneItem.selected = true;
                }
                updatedData.push(cloneItem);
            });
            
            state.stepsInitData.storeLocated = updatedData;         
               
        },
        handleStoreShoppers(state, action) {
            let storeShoppersCloned = [...state.stepsInitData.storeShoppers];
            let itemSelected = action.payload;
            const updatedData = [];
            const { shoppersCalculateRank } = state;

            storeShoppersCloned.map(item => {
                let itemCloned = {...item};
                
                if (itemCloned.id === itemSelected.id) {

                    if (itemCloned.badges.active) {
                        itemCloned.badges.active = false;
                        itemCloned.badges.rank = null;

                        if (itemSelected.badges.rank === 1 && shoppersCalculateRank.hasOwnProperty("rankOne")) {
                            delete shoppersCalculateRank.rankOne;
                            delete state.selectedItemsShoppersStore.rankOne;
                            return false;
                        } else if (itemSelected.badges.rank === 2 && shoppersCalculateRank.hasOwnProperty("rankTwo")) {
                            delete shoppersCalculateRank.rankTwo;
                            delete state.selectedItemsShoppersStore.rankTwo;
                            return false;
                        } else if (itemSelected.badges.rank === 3 && shoppersCalculateRank.hasOwnProperty("rankThree")) {
                            delete shoppersCalculateRank.rankThree;
                            delete state.selectedItemsShoppersStore.rankThree;
                            return false;
                        }

                        return false;
                    } else if (itemSelected.badges.rank === null && !shoppersCalculateRank.hasOwnProperty("rankOne")) {
                        itemCloned.badges.active = true;
                        itemCloned.badges.rank = 1;
                        shoppersCalculateRank.rankOne = true;
                        state.selectedItemsShoppersStore.rankOne = item;

                    } else if (itemSelected.badges.rank === null && !shoppersCalculateRank.hasOwnProperty("rankTwo")) {
                        itemCloned.badges.active = true;
                        itemCloned.badges.rank = 2;
                        shoppersCalculateRank.rankTwo = true;
                        state.selectedItemsShoppersStore.rankTwo = item;

                    } else if (itemSelected.badges.rank === null && !shoppersCalculateRank.hasOwnProperty("rankThree")) {
                        itemCloned.badges.active = true;
                        itemCloned.badges.rank = 3;
                        shoppersCalculateRank.rankThree = true;
                        state.selectedItemsShoppersStore.rankThree = item;
                    }
                }

                return updatedData.push(itemCloned);
            });
        },
        saveCluster(state, action) {
            state.collectedData.storeSelectedCluster = action.payload;
        },
        saveStoreSize(state, action) {
            // save selected item from Store size step
            state.collectedData.storeSizeSelectedItem = action.payload;

            let cloneState = current(state.stepsInitData.storeSize);
            const { id } = action.payload;
            const updatedData = [];
            

            cloneState.forEach(item => {
                let cloneItem = {...item};
                
                cloneItem.checked = false;
                
                if (cloneItem.id === id) {
                    cloneItem.checked = true;
                }
                updatedData.push(cloneItem);
            });
            
            state.stepsInitData.storeSize = updatedData;       
        },
        changeActiveStoreCloseTo(state, action) {
            let cloneState = current(state.stepsInitData.storeCloseTo);
            const { id, title } = action.payload;
            let updatedData = [];
            

            cloneState.map(item => {
                let cloneItem = {...item};
                
                if (cloneItem.id === id) {
                    cloneItem.selected = !cloneItem.selected;
                }
                
                if (cloneItem.id === 6) {
                    cloneItem.selected = false;
                }

                return updatedData.push(cloneItem);
            });
            
            if (title === "None of these") {
                updatedData = [];
                
                cloneState.map(item => {
                    let cloneItem = {...item}

                    if (item.id < 6) {
                        cloneItem.selected = false;
                    } else if (item.id === 6) {
                        cloneItem.selected = !cloneItem.selected;
                    }

                    return updatedData.push(cloneItem);
                });
            }

            state.stepsInitData.storeCloseTo = updatedData;      
        },
        saveStoreCloseTo(state) {
            let cloneState = current(state.stepsInitData.storeCloseTo);
            let selectedItems = [];
            let selectedItemsArrayOfStrings = [];

            cloneState.map(item => {
                if (item.selected === true) {
                    selectedItems.push(item);
                    selectedItemsArrayOfStrings.push(item.title);
                }

                return true;
            });

            // save selected item from Store close to step
            state.collectedData.storeCloseTo = selectedItems;
            state.collectedData.storeCloseToStrings = selectedItemsArrayOfStrings;
        },
        saveStoreTurnover(state, action) {
            state.collectedData.storeTurnover = action.payload;
        },
        detectScottish(state, action) {
            state.scottishDetected = action.payload;
        },
        saveClusterFromSearch(state, action) {
            state.collectedData.storeSelectedCluster = {
                cluster: action.payload.store_cluster
            }
        },
        resetCollecetedData(state) {
            state.collectedData = {};    
            state.stepsInitData = stepsInitData;    
            state.selectedItemsShoppersStore = {};
            state.shoppersCalculateRank = {};
        }
    }
});

export const stepsStoreDataAction = stepsStoreData.actions;

export default stepsStoreData;