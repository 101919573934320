import React from "react";
import { useSelector } from 'react-redux';

const ProgressBar = () => {
    const activeStep = useSelector(store => store.stepsController.activeStep);

    return(
        <div className='progress_component'>
            <div className='text_container'>
                {activeStep === 7 ? 
                    <p><strong>Thank you for telling us about your store. Based on your store location, shoppers and size, below are your personalised Crisps & Snacks planograms</strong></p> 
                    :
                    <p>Tell us more about your store so we can suggest planograms and products that will increase your Crisps & Snacks sales!</p>
                }
            </div>

            <div className='progress_bar'>
                <div className='progress_circle'>
                    <div className={activeStep === 7 ? 'circle circle--checked' : 'circle circle--active'}>
                        <p>1</p>
                    </div>
                    <p>Store Details</p>
                </div>
                <div className='progress_circle'>
                    <div className={activeStep === 7 ? 'circle circle--active' : 'circle circle--default'}>
                        <p>2</p>
                    </div>
                    <p>Your Personalised Planograms</p>
                </div> 
                <div className='progress_circle'>
                    <div className='circle circle--default'>
                        <p>3</p>
                    </div>
                    <p>Personalised Products</p>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar