import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { stepsControllerAction } from '../../../store/reducers/stepsController';
import { stepsStoreDataAction } from '../../../store/reducers/stepsStoreData';

const StoreTurnover = () => {
    const dispatch = useDispatch();
    const [turnover, setTurnover] = useState('0')
    const [turnoverClassName, setTurnoverClassName] = useState('')
    const [error, setError] = useState("")
    
    const handleOnChange = e => {
        if (e.target.value > 0 && error.length > 0) {
            setError("")
        }

        setTurnover(e.target.value);
        dispatch(stepsStoreDataAction.saveStoreTurnover(e.target.value))
    }

    const handleNext = () => {
        
        if (turnover > 0) {
            dispatch(stepsControllerAction.previousNextStep("next"))
        } else {
            setError("Please select your monthly revenue")
        }
    }

    const change = e => {
        if (isNaN(e.target.value)) {
            return false;
        } else {
            setTurnover(e.target.value === '' ? Number('') : Number(e.target.value));
        }
            
    }

    const handleChangeOnBlur = () => {
        setTurnoverClassName("");
        if (parseInt(turnover) > 50000) {
            setTurnover(50000);
        }
    }

    const onFocus = () => {
        setTurnoverClassName("active");
    }

    useEffect(() => {
        dispatch(stepsControllerAction.updateDataLayer({
            "event": "Enter Subsite",
            "dlv.page": '09 - Store Turnover',
            "dlv.url": '/store-turnover'
        }));
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            <div className='container'>
                <h2 className="component-title">What is your average weekly store turnover?</h2>

                <div className='turnover-text'>
                    <p>Your data is confidential and will not be shared with third parties</p>
                </div>

                
                <div className='range_container'>
                    <div className='range_wrapper'>
                        {error && <p className="turnover-error-msg">{error}</p>}
                        <div className='range-input_wrapper'>
                            <input 
                                type="range" 
                                name="storeTurnover" 
                                min="0" 
                                max="50000" 
                                style={{backgroundSize: `${(turnover * 100) / 50000}% 100%`}} 
                                onChange={handleOnChange} 
                                value={turnover} 
                            />
                            <div className='range__labels'>
                                <p>&#163; <strong>0</strong></p>
                                <p>&#163; <strong>25,000</strong></p>
                                <p>&#163; <strong>50,000</strong></p>
                            </div>
                        </div>
                    </div>
                    <div 
                        className='turnover'
                    >
                        <div className="pound">&#163;</div>
                        <strong className={turnoverClassName}>{parseInt(turnover).toLocaleString("en")}</strong>
                        <input 
                            className="turnover__input"
                            type="text"
                            value={turnover}
                            onFocus={onFocus}
                            onBlur={handleChangeOnBlur}
                            onChange={change}
                        />
                    </div>
                </div>
                <div className='button-container'>
                    <button
                        className="button grey"    
                        onClick={() => dispatch(stepsControllerAction.previousNextStep("previous"))}
                    >
                        Previous
                    </button> 
                    <button
                        className="button"    
                        onClick={handleNext}
                    >
                        Next 
                    </button> 
                </div>
            </div>
        </div>
    );
}

export default StoreTurnover;