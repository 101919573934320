import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { stepsControllerAction } from "../../../../../store/reducers/stepsController";

const Search = ({ handleNext, searchField, handleKeyPress, handleSearch, searchError }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(stepsControllerAction.updateDataLayer({
            "event": "Enter Subsite",
            "dlv.page": '02 - Store Search',
            "dlv.url": '/search'
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <Fragment>
            <div className="search">
                <h2 className="component-title">Please enter your store postcode to receive your personalised planograms, product recommendations and NEW HFSS legislation guidance</h2>

                <p className="search-helper-desc">Please leave a space when entering the two parts of your postcode</p>

                <p className="error">{searchError.length > 0 && searchError}</p>
                <div className="search__form">
                    <input 
                        className="input-field" 
                        type="text" 
                        name="search"
                        value={searchField}
                        minLength="3"
                        onChange={handleSearch}
                        onKeyPress={handleKeyPress}
                        placeholder="Enter postcode here"
                    />

                    <button
                        className={`button ${!searchField && 'disable'}`}
                        onClick={handleNext}
                        disabled={searchField.length > 0 ? false : true}
                        >
                        Next 
                    </button> 
                </div>
            </div>  
        </Fragment>
    ); 
}

export default Search;