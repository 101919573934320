import React from 'react';

const SearchResulZero = () => {
    return(
        <div className="zero-search-response">
            <h4>We couldn't find a store for the entered postcode.<br/>Please click NEXT to receive your personalised Crisps &amp; Snacks product recommendations</h4>
        </div>
    )
}

export default SearchResulZero;