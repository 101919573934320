import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { stepsControllerAction } from '../../../store/reducers/stepsController';
import { stepsStoreDataAction } from '../../../store/reducers/stepsStoreData';

const StoreSize = () => {
    const dispatch = useDispatch();
    const storeSize = useSelector(store => store.stepsData.stepsInitData.storeSize);
    const collectedData = useSelector(store => store.stepsData.collectedData);
    
    const handleNext = () => {
        if (!collectedData.hasOwnProperty('storeSizeSelectedItem')) {
            return false;
        }
        
        dispatch(stepsControllerAction.previousNextStep("next"));
    }

    useEffect(() => {
        dispatch(stepsControllerAction.updateDataLayer({
            "event": "Enter Subsite",
            "dlv.page": '07 - Store Size',
            "dlv.url": '/store-size'
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            <h2 className="component-title">How big is your store? (Select One)</h2>

            <div className='store-size_buttons'>
                {storeSize.map( store =>    
                    <button 
                        type="button" 
                        value={store.id} 
                        key={store.id} 
                        className={`button store-size ${store.checked && 'checked'}`}
                        onClick={() => dispatch(stepsStoreDataAction.saveStoreSize(store))}
                    >
                        {store.title}
                    </button>
                )}
            </div>

            <div className='button-container'>
                <button
                    className="button grey"    
                    onClick={() => dispatch(stepsControllerAction.previousNextStep("previous"))}
                >
                    Previous
                </button> 
                <button
                    className="button"    
                    onClick={handleNext}
                >
                    Next 
                </button> 
            </div>

        </div>
    );
}

export default StoreSize;