import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { stepsControllerAction } from '../../store/reducers/stepsController';
import Modal from '../Common/Modal';

const Layout = ({children}) => {
    const dispatch = useDispatch();
    const modalStatus = useSelector(state => state.stepsController.modalStatus);

    return(
        <Fragment>
            <div className="layout">
                <header className="header">
                    <div  className="header__logo"> 
                        <img 
                            className="logo-bg"
                            src="/assets/logo-bg.png" 
                            alt=""
                        />
                        <img 
                            className="logo"
                            src="/assets/logo.png" 
                            alt=""
                        />
                    </div>

                    <img 
                        className="header__bg desktop"
                        src="/assets/header-background.png" 
                        alt=""
                    />
                    <img 
                        className="header__bg mobile"
                        src="/assets/header-background-mobile.png" 
                        alt=""
                    />
                    <button 
                        className="button transparent"
                        onClick={() => dispatch(stepsControllerAction.triggerModal('show'))}
                    >
                        Restart 
                    </button>
                </header>
            </div>

            <div className="container">
                { children }
            </div>
            
            { modalStatus && <Modal /> }
        </Fragment>
    );
}

export default Layout;