import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Card from '../../Common/Card';
import { stepsControllerAction } from '../../../store/reducers/stepsController';
import { stepsStoreDataAction } from '../../../store/reducers/stepsStoreData';

const StoreCloseTo = () => {
    const dispatch = useDispatch();
    const stepsData = useSelector(store => store.stepsData.stepsInitData.storeCloseTo);
    const storeCloseTo = useSelector(store => store.stepsData.stepsInitData.storeCloseTo);

    
    const handleNext = () => {           
        let validStatus = false;

        storeCloseTo.map(item => {
            if (item.selected === true) {
                return validStatus = true;
            }
            
            return true;
        })

        if (validStatus) {
            dispatch(stepsStoreDataAction.saveStoreCloseTo())
            dispatch(stepsControllerAction.previousNextStep("next"));
        }
    }

    useEffect(() => {
        dispatch(stepsControllerAction.updateDataLayer({
            "event": "Enter Subsite",
            "dlv.page": '08 - Store Environment',
            "dlv.url": '/store-environment'
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            <h2 className="component-title">What is your store close to? (Select All That Apply)</h2>

            <div className='cards-container store-area'>
                {stepsData.map((store, i) => 
                    <Card 
                        key={i}
                        cardData={store}
                        onClick={() => dispatch(stepsStoreDataAction.changeActiveStoreCloseTo(store))}
                    />
                )}
            </div>

            <div className='button-container'>
                <button
                    className="button grey"    
                    onClick={() => dispatch(stepsControllerAction.previousNextStep("previous"))}
                >
                    Previous
                </button> 
                <button
                    className="button"    
                    onClick={handleNext}
                >
                    Next 
                </button> 
            </div>

        </div>
    );
    
}

export default StoreCloseTo;