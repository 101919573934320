import { configureStore } from '@reduxjs/toolkit';

import stepsController from './reducers/stepsController';
import stepsStoreData from './reducers/stepsStoreData';

const store = configureStore({
    reducer: { 
        stepsController: stepsController.reducer,
        stepsData: stepsStoreData.reducer
    }
});

export default store; 