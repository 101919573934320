import { createSlice } from '@reduxjs/toolkit';

const stepsController = createSlice({
    name: "stepsController",
    initialState: {
        goToStoreStatus: false,
        modalStatus: false,
        activeStep: 0
    },
    reducers: {
        goToStore(state) {
            state.goToStoreStatus = true;
        },
        resetStep(state) {
            state.goToStoreStatus = false;
            state.modalStatus =  false;
            state.activeStep = 0;
        },
        triggerModal(state, action) {
            if (action.payload === 'show')  {
                state.modalStatus =  true;
            } else {
                state.modalStatus =  false;
            }
        },
        previousNextStep(state, action) {
            if (action.payload === 'next') {
                state.activeStep++;
            } else {
                state.activeStep--;
            }
        },
        goToPlanogram(state) {
            state.activeStep = 7   
        },
        updateDataLayer(state, action) {
            window.dataLayer.push(action.payload);
        }
    }
});

export const stepsControllerAction = stepsController.actions;

export default stepsController;