import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { stepsControllerAction } from '../../../store/reducers/stepsController';
import { stepsStoreDataAction } from '../../../store/reducers/stepsStoreData';
import scotCodes from '../components/scottish-zip/scotZips.json';

const StoreInfo = () => {
    const dispatch = useDispatch();
    const storeCollected = useSelector(store =>  store.stepsData.collectedData)

    const [inputState, setInputState] = useState({
        storeName: storeCollected.hasOwnProperty("storeInfo") ? storeCollected.storeInfo.storeName : "",
        street: storeCollected.hasOwnProperty("storeInfo") ? storeCollected.storeInfo.street : "",
        city: storeCollected.hasOwnProperty("storeInfo") ? storeCollected.storeInfo.city : "",
        state: storeCollected.hasOwnProperty("storeInfo") ? storeCollected.storeInfo.state : "",
        postal: storeCollected.hasOwnProperty("storeInfo") ? storeCollected.storeInfo.postal : ""
    })
    const [errorText, setErrorText] = useState({});
    const [validForm, setValidForm] = useState(false);

    const handleInput = e => {
        const { value } = e.target;
        let cloneValue = inputState[e.target.name].length > 0 ? value : value.replace(/\s/g, '');

        setInputState({
            ...inputState,
            [e.target.name]: cloneValue
        })
    }

    const validate = values => {
        const allErrors = {};

        if(values.storeName.trim().length <= 1) {
            allErrors.storeName = "Please enter Store Name";
        }

        if(values.street.trim().length <= 1) {
            allErrors.street = "Please enter Street Address";
        }

        if(values.city.trim().length <= 1) {
            allErrors.city = "Please enter City";
        }
        if(values.state.trim().length <= 1) {
            allErrors.state = "Please enter State";
        }

        if(values.postal.trim().length <= 1) {
            allErrors.postal = "Please enter Postal / Zip Code";
        }
        ;
        if(Object.keys(allErrors).length === 0) {
            const searchSubstring = inputState.postal.substring(0, inputState.postal.indexOf(' '));
            
            setValidForm(true);

            scotCodes.map(item => {
                if (searchSubstring.includes(item)) {
                    return dispatch(stepsStoreDataAction.detectScottish(true))                
                }
                
                return true;
            });

            dispatch(stepsControllerAction.previousNextStep("next"));
            dispatch(stepsStoreDataAction.saveStoreInfo(inputState));
        } else {
            setValidForm(false);
        }

        return allErrors;
    }

    const submitForm = e => {
        e.preventDefault();
        setErrorText(validate(inputState));
        
        if(validForm) {
            dispatch(stepsControllerAction.previousNextStep("next"));
            dispatch(stepsStoreDataAction.saveStoreInfo(inputState));
        }
    }

    useEffect(() => {
        dispatch(stepsControllerAction.updateDataLayer({
            "event": "Enter Subsite",
            "dlv.page": '04 - New Store',
            "dlv.url": '/new-store'
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            <div className='container'>
                <div className='text_container'>
                    <h3>Unfortunately we cannot locate your store in our records.</h3>
                    <p>Please enter in your store information below and click <strong>NEXT</strong> to receive your personalised Crisps & Snacks product recommendations</p>
                </div>
                <div className='form-container'>
                    <p className="form-desc">&nbsp;</p>
                    <form onSubmit={submitForm}>
                        <div className='input-container'>
                            <input type="text" name="storeName" className={`input-field ${errorText.storeName && 'error'}`} placeholder='Enter store name' required value={inputState.storeName} onChange={handleInput} />
                            {<small>{errorText.storeName}</small>}
                        </div>
                        <div className='input-container'>
                            <input type="text" name="street" className={`input-field ${errorText.street && 'error'}`} placeholder='Street Address' required value={inputState.street} onChange={handleInput} />
                            {<small>{errorText.street}</small>}
                        </div>
                        
                        <div className='input_wrapper'>
                            <div className='input-container'>
                                <input type="text" name="city" className={`input-field ${errorText.city && 'error'}`} placeholder='City' required value={inputState.city} onChange={handleInput} />
                                {<small>{errorText.city}</small>}
                            </div>
                            <div className='input-container'>
                                <input type="text" name="state" className={`input-field ${errorText.state && 'error'}`} placeholder='County' required value={inputState.state} onChange={handleInput} />
                                {<small>{errorText.state}</small>}
                            </div>
                        </div>
                        <div className='input-container'>
                            <input type="text" name="postal" className={`input-field ${errorText.postal && 'error'}`} placeholder='Postal / Zip Code' required value={inputState.postal} onChange={handleInput} />
                            {<small>{errorText.postal}</small>}
                        </div>
                    </form>

                    <button
                        className="button"    
                        onClick={submitForm}
                    >
                        Next 
                    </button>
                </div>
            </div>
 
        </div>
    );
}

export default StoreInfo;