import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { stepsControllerAction } from '../../../store/reducers/stepsController';
import { stepsStoreDataAction } from '../../../store/reducers/stepsStoreData';


const Modal = () => {
    const dispatch = useDispatch();

    const resetSteps = () => {
        dispatch(stepsControllerAction.resetStep())
        dispatch(stepsStoreDataAction.resetCollecetedData())
    }

    return(
        <Fragment>
            <div 
                className="modal-mask"
                onClick={() => dispatch(stepsControllerAction.triggerModal('hide'))}    
            >
            </div>
            <div className="modal">
                <header className="modal__header">
                    <h2>Alerts</h2>
                    <button
                        onClick={() => dispatch(stepsControllerAction.triggerModal('hide'))}    
                    >
                        <img src="/assets/icons/close-icon.svg" alt=""/>
                    </button>
                </header>

                <div className="modal__content">
                    <p>Are you sure you want to close the tool?</p>
                    <p>You will lose all of your progress and your answers will not be saved.</p>
                </div>

                <div className="modal__buttons">
                <button 
                    className="button transparent border"
                    onClick={resetSteps}
                >
                    Yes
                </button>
                <button 
                    className="button"
                    onClick={() => dispatch(stepsControllerAction.triggerModal('hide'))}
                >
                    No
                </button>
            </div>
            </div>
        </Fragment>
    )
}

export default Modal;