import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { stepsControllerAction } from '../../../store/reducers/stepsController';
import { stepsStoreDataAction } from '../../../store/reducers/stepsStoreData';
import Card from '../../Common/Card';

const StoreLocated = () => {
    const dispatch = useDispatch();
    const storeLocated = useSelector(store => store.stepsData.stepsInitData.storeLocated);
    const collectedData = useSelector(store => store.stepsData.collectedData);

    const handleNext = () => {
        if (!collectedData.hasOwnProperty('storeLocatedSelectedItem')) {
            return false;
        }

        dispatch(stepsControllerAction.previousNextStep("next"))
    }

    useEffect(() => {
        dispatch(stepsControllerAction.updateDataLayer({
            "event": "Enter Subsite",
            "dlv.page": '05 - Store Location',
            "dlv.url": '/store-location'
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            <h2 className="component-title">Where is your store located? (Select One)</h2>
            
            <div className='cards-container'>
                {storeLocated.map((store, i) => 
                    <Card 
                        key={i}
                        cardData={store}
                        onClick={() => dispatch(stepsStoreDataAction.handleStoreLocated(store))}
                    />
                )}
            </div>
            
            <div className='button-container'>
                <button
                    className="button"    
                    onClick={handleNext}
                >
                    Next 
                </button> 
            </div>
             
            
        </div>
    );
}

export default StoreLocated;