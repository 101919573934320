import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { stepsControllerAction } from '../../store/reducers/stepsController';

const IntroPage = () => {
    const dispatch = useDispatch();

    const handleNext = () => {
        dispatch(stepsControllerAction.goToStore())
    }
    
    useEffect(() => {
        dispatch(stepsControllerAction.updateDataLayer({
            "event": "Enter Subsite",
            "dlv.page": '01 - Start',
            "dlv.url": '/start'
        }));
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            <header className="intro-page-header">
                <img 
                    className="logo"
                    src="/assets/logo.png" 
                    alt=""
                />
            </header>
            
            <div className="intro-page">
                <div className="intro-page__inner">
                    <h1>Three simple steps to maximise your Crisps & Snacks sales.</h1>

                    <div className="intro-page__list">
                        <div className="list-item">
                            <span>1</span>
                            <p>IDENTIFY YOUR STORE</p>
                        </div>

                        <div className="list-item">
                            <span>2</span>
                            <p>RECEIVE YOUR PERSONALISED PLANOGRAMS AND HFSS LEGISLATION GUIDANCE</p>
                        </div>

                        <div className="list-item">
                            <span>3</span>
                            <p>BUY YOUR PERSONALISED PRODUCTS</p>
                        </div>
                    </div>

                    <button 
                        className="button"
                        onClick={handleNext}
                    >
                        CLICK HERE TO TELLS US MORE ABOUT YOUR STORE TO INCREASE YOUR SALES
                    </button>
                    
                </div>
                <picture>
                    <source srcSet="/assets/intro-page/intro-page.webp" type="image/webp" />
                    <source srcSet="/assets/intro-page/intro-page.png" type="image/png" /> 
                    <img 
                        className="intro-page__mobile-img"
                        src="/assets/intro-page/intro-page.png"
                        alt=""
                    />
                </picture>
                <picture>
                    <source srcSet="/assets/intro-page/intro-desktop-left.webp" type="image/webp" />
                    <source srcSet="/assets/intro-page/intro-desktop-left.png" type="image/png" /> 
                    <img 
                        className="intro-page__desktop left"
                        src="/assets/intro-page/intro-desktop-left.png" 
                        alt=""
                    />
                </picture>
                <picture>
                    <source srcSet="/assets/intro-page/intro-desktop-right.webp" type="image/webp" />
                    <source srcSet="/assets/intro-page/intro-desktop-right.png" type="image/png" /> 
                    <img 
                        className="intro-page__desktop right"
                        src="/assets/intro-page/intro-desktop-right.png" 
                        alt=""
                    />
                </picture>
            </div>
        </div>
    );
}

export default IntroPage;