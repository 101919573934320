import React from 'react';

const Card = ({ cardData, onClick }) => {
    return(
        <div className='card' onClick={onClick}>
            <div className={cardData.image ? 'card_image' : 'card_image grey'}>
                {cardData.image ? <img src={cardData.image} alt={cardData.title}/> : <p>None of these</p>}
                
                {cardData.hasOwnProperty("badges") && 
                    <div className="check-box">
                        {
                            cardData.badges.rank &&
                            <span className="badges">
                                {cardData.badges.rank}
                            </span>
                        }
                    </div>}

                {cardData.hasOwnProperty("selected") && <div className={`check-box ${cardData.selected ? "checked" : ""}`}></div>}
            </div>

            <div className='card__text'>
                <p>{cardData.title}</p>
            </div>
        </div>
    )
}

export default Card;